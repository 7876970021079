<template>
    <wit-modal
        v-model="show"
        @input="emitInput"
        title="Upgrade Version"
        variant="primary"
        disableDBlock
        size="lg"
        custom-class="upgrade-version-modal"
    >
        <div v-if="upgradeQuestionsChanged">
            <div class="small-info-text">
                There have been changes to some of the fields. If you wish to upgrade, you will need to fill them in
                again.
            </div>

            <answer-builder
                ref="answerBuilder"
                v-model="$v.variables.$model"
                :questions="upgradeQuestions"
                :valid.sync="answersValid"
                :project="project"
                :relay-custom-queries="relayCustomQueries"
                :silent="true"
            />

            <feedback
                v-if="clicked"
                :state="!$v.$invalid"
                invalid="You have to fill in all the required fields"
                valid="This step is complete"
            />
        </div>

        <b-row class="d-block text-center upgrade-version-modal--footer">
            <div class="small-info-text">
                <span v-if="!upgradeQuestionsChanged">
                    You can safely upgrade to a newer version. No additional change is required. </span
                >If you're sure you want to upgrade, click <strong>Upgrade</strong>.
            </div>
            <b-button variant="danger" size="md" @click="upgrade" :disabled="clicked && $v.$invalid">Upgrade</b-button>
        </b-row>
    </wit-modal>
</template>

<script>
import {validationMixin} from 'vuelidate'

import Feedback from '@/components/Feedback.vue'
import WitInputGroup from '@/components/Inputs/WitInputGroup.vue'
import WitModal from '@/components/Modals/WitModal.vue'

export default {
    components: {
        Feedback,
        WitInputGroup,
        WitModal,
        AnswerBuilder: () => import('./AnswerBuilder/AnswerBuilder'),
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        upgradeQuestionsChanged: {
            type: Boolean,
            required: true,
        },
        project: {
            required: true,
        },
        upgradeQuestions: {
            type: Array,
            default: () => [],
        },
        relayCustomQueries: {
            type: Array,
            default: null,
        },
    },
    mixins: [validationMixin],
    validations: {
        variables: {
            valid: function () {
                return this.upgradeQuestionsChanged ? this.answersValid : true
            },
        },
    },
    data() {
        return {
            show: false,
            variables: [],
            answersValid: false,
            clicked: false,
        }
    },
    watch: {
        value() {
            this.show = this.value
        },
    },
    created() {
        this.show = this.value
    },
    methods: {
        emitInput() {
            this.$emit('input', this.show)
        },

        upgrade() {
            this.clicked = true
            if (!this.$v.$invalid) {
                this.$emit('upgrade', this.upgradeQuestionsChanged ? this.variables : null)
            }
        },
    },
}
</script>

<style>
.upgrade-version-modal--footer {
    margin: 0 20px;
}
</style>
